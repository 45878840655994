import { Fragment, type ReactNode } from 'react';

import type { ChildrenProps } from '@/types/common.types';

import { UserAvatar } from '@/components/Avatars/UserAvatar';
import { ExitProfileButton } from '@/components/Buttons/ExitProfileButton';

import { LoginButton } from '@/layout/DesktopMenu/LoginButton';
import styles from '@/layout/MobileMenu/SidebarToggler/SidebarUserPanel.module.scss';

import { useStore } from '@/contexts/StoreContext';

interface SidebarUserPanelProps extends ChildrenProps {
  isAuthenticated: boolean;
  initials: ReactNode;
}

export const SidebarUserPanel = (props: SidebarUserPanelProps) => {
  const { isAuthenticated, children, initials } = props;

  const { hideSidebar } = useStore(state => state);

  return (
    <div className={styles.userPanel}>
      {isAuthenticated ? (
        <Fragment>
          <div className={styles.userDetails}>
            <UserAvatar as="link" href="/user-profile/account" onClick={hideSidebar} />
            {children}
          </div>
          <ExitProfileButton onExited={hideSidebar} />
        </Fragment>
      ) : (
        <Fragment>
          <div className={styles.message}>Вы не авторизованы</div>
          <LoginButton onClick={hideSidebar} />
        </Fragment>
      )}
    </div>
  );
};
