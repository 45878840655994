import type { ClassNameProps } from '@/types/common.types';

const SessionsSVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 512 512"
      fill="currentColor"
      xmlSpace="preserve"
      className={className}
    >
      <path
        d="M411.429,442.514c0,5.554,4.503,10.057,10.057,10.057h80.457c2.667,0,5.225-1.06,7.112-2.946
				c1.886-1.886,2.946-4.444,2.946-7.112v-8.229H411.429V442.514z"
      />
      <path
        d="M501.943,269.714h-80.457c-5.554,0-10.057,4.503-10.057,10.057V288H512v-8.229
				C512,274.217,507.497,269.714,501.943,269.714z"
      />
      <polygon points="210.286,333.714 310.857,333.714 393.143,333.714 393.143,306.286 210.286,306.286" />
      <path
        d="M420.571,86.857c0-15.148-12.28-27.429-27.429-27.429H118.857c-15.148,0-27.429,12.28-27.429,27.429v54.857h77.586
				c22.783,0.025,41.246,18.488,41.271,41.271V288h182.857v-8.229c0.051-15.261,12.176-27.741,27.429-28.233V86.857z"
      />
      <rect x="210.286" y="352" width="91.429" height="36.571" />
      <rect x="411.429" y="306.286" width="100.571" height="109.714" />
      <path d="M0,429.586c0.01,12.69,10.295,22.975,22.985,22.985h146.03c12.69-0.01,22.975-10.295,22.985-22.985v-4.443H0V429.586z" />
      <polygon points="0,406.857 192,406.857 192,397.714 192,342.857 192,205.714 0,205.714" />
      <path
        d="M169.015,160H22.985C10.295,160.01,0.01,170.295,0,182.985v4.443h192v-4.443C191.99,170.295,181.705,160.01,169.015,160z
				"
      />
      <path d="M347.429,406.857H210.286v18.286h146.286V416C356.571,410.951,352.478,406.857,347.429,406.857z" />
    </svg>
  );
};

export default SessionsSVG;
