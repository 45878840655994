import type { ClassNameProps } from '@/types/common.types';

const SupportSVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M11.4397 10.1649C10.8168 11.0205 9.80769 11.5777 8.67077 11.5777H7.07551C6.52443 11.5777 6.05514 11.2279 5.87743 10.7382C5.65808 10.6728 5.44298 10.5878 5.23307 10.4835C4.78856 10.2627 4.38493 9.96344 4.02963 9.59277C1.72492 10.0991 0 12.1527 0 14.6095V14.9306C0 15.3721 0.357871 15.7299 0.799308 15.7299H13.2007C13.6421 15.7299 14 15.3721 14 14.9306V14.6095C14 12.7116 12.9705 11.0543 11.4397 10.1649Z" />
      <path d="M3.1801 7.9451C3.52364 7.9451 3.82265 7.7547 3.97756 7.47372C3.98164 7.48484 3.98578 7.4959 3.98995 7.50695C3.99118 7.51027 3.99242 7.51359 3.99365 7.51691C4.35706 8.47349 5.06418 9.2754 5.99661 9.62578C6.22211 9.26746 6.62088 9.02915 7.07556 9.02915H8.67082C8.82994 9.02915 8.97805 8.98426 9.10788 8.91168C9.32592 8.78975 9.52201 8.48044 9.62326 8.31649C9.78529 8.05413 9.91084 7.77623 10.0222 7.47324C10.0847 7.58685 10.1707 7.68568 10.2738 7.76312V8.15429C10.2738 9.03819 9.55472 9.75732 8.67078 9.75732H7.07552C6.77394 9.75732 6.52944 10.0018 6.52944 10.3034C6.52944 10.605 6.77394 10.8495 7.07552 10.8495H8.67078C10.1569 10.8495 11.366 9.64042 11.366 8.15429V7.76312C11.587 7.59705 11.73 7.33274 11.73 7.03499V5.38836V4.75694C11.73 4.45307 11.581 4.1842 11.3522 4.01888C11.1747 1.77314 9.29058 0 7 0C4.70939 0 2.82528 1.77314 2.64781 4.01885C2.41899 4.18417 2.26996 4.45307 2.26996 4.7569V7.03492C2.26996 7.53901 2.67947 7.9451 3.1801 7.9451ZM7 1.0922C8.69566 1.0922 10.0943 2.38803 10.2577 4.04144C10.1504 4.12586 10.0625 4.23375 10.0019 4.35756C9.48666 3.0132 8.33639 2.07721 6.99997 2.07721C5.63473 2.07721 4.50291 3.04536 4.00001 4.35219C3.99933 4.35397 3.99868 4.35578 3.99799 4.35759C3.93742 4.23379 3.84951 4.12589 3.7422 4.04147C3.90577 2.38803 5.30434 1.0922 7 1.0922Z" />
    </svg>
  );
};

export default SupportSVG;
