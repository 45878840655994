'use client';

import { Fragment, useCallback, useEffect } from 'react';
import { useKey } from 'react-use';

import { MovieList } from '@/api/types/movie.types';

import { SearchResultsButton } from '@/layout/SearchBar/SearchResultsButton';
import styles from '@/layout/SearchBar/SuggestedDropdown.module.scss';
import { SuggestionCard } from '@/layout/SearchBar/SuggestionCard';

// =================================================================

interface SuggestedDropdownProps {
  movies: MovieList[];
  toggleSearchBar: () => void;
  query: string;
  indexOfHighlightedElement: number;
  setIndexOfHighlightedElement: (index: number | null) => void;
}

// =================================================================

export const SuggestedDropdown = (props: SuggestedDropdownProps) => {
  const {
    movies,
    toggleSearchBar,
    query,
    indexOfHighlightedElement,
    setIndexOfHighlightedElement,
  } = props;

  const hasSuggestions = movies.length > 0;

  const handleSuggestionHovered = useCallback(
    (index: number) => {
      setIndexOfHighlightedElement(index);
    },
    [setIndexOfHighlightedElement],
  );

  const handleSearchResultsButtonHovered = useCallback(() => {
    setIndexOfHighlightedElement(movies.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movies]);

  useKey(
    'ArrowUp',
    e => {
      e.preventDefault();

      if (hasSuggestions) {
        if (indexOfHighlightedElement === null || indexOfHighlightedElement === 0) {
          setIndexOfHighlightedElement(movies.length);
        } else {
          setIndexOfHighlightedElement(indexOfHighlightedElement - 1);
        }
      }
    },
    {},
    [hasSuggestions, indexOfHighlightedElement, setIndexOfHighlightedElement],
  );

  useKey(
    'ArrowDown',
    e => {
      e.preventDefault();

      if (hasSuggestions) {
        if (indexOfHighlightedElement === null) {
          setIndexOfHighlightedElement(0);
        } else {
          setIndexOfHighlightedElement((indexOfHighlightedElement + 1) % (movies.length + 1));
        }
      }
    },
    {},
    [hasSuggestions, indexOfHighlightedElement, setIndexOfHighlightedElement],
  );

  useEffect(() => {
    setIndexOfHighlightedElement(null);
  }, [query, setIndexOfHighlightedElement]);

  return (
    <div className={styles.suggestedDropdown}>
      {movies.length > 0 ? (
        <Fragment>
          <ul className={styles.suggestionsList}>
            {movies.map((movie, index) => (
              <li key={movie.id} className={styles.suggestionsListItem} onClick={toggleSearchBar}>
                <SuggestionCard
                  index={index}
                  movie={movie}
                  onHovered={handleSuggestionHovered}
                  isHighlighted={indexOfHighlightedElement === index}
                />
              </li>
            ))}
          </ul>
          <SearchResultsButton
            query={query}
            toggleSearchBar={toggleSearchBar}
            onHovered={handleSearchResultsButtonHovered}
            isHighlighted={indexOfHighlightedElement === movies.length}
          />
        </Fragment>
      ) : (
        <div className={styles.placeholder}>Ничего не найдено</div>
      )}
    </div>
  );
};
