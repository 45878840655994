'use client';

import clsx from 'clsx';

import TickCircleSVG from '@/icons/TickCircleSVG';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { useDropdownContext } from '@/components/Dropdown/contexts/useDropdownContext';

import styles from '@/components/Dropdown/DropdownOption.module.scss';

// =================================================================

interface DropdownOptionProps extends ChildrenProps, ClassNameProps {
  value: string | number;
}

// =================================================================

export const DropdownOption = (props: DropdownOptionProps) => {
  const { value, children, className } = props;

  const { onChange, value: defaultValue, toggleDropdown } = useDropdownContext();

  return (
    <li
      className={clsx(className, {
        [styles.option]: true,
        [styles.active]: value === defaultValue,
      })}
    >
      <button
        onClick={() => {
          if (typeof onChange === 'function') {
            onChange(value);
          }
          toggleDropdown();
        }}
      >
        {children}
        {value === defaultValue && <TickCircleSVG />}
      </button>
    </li>
  );
};
