import type { SVGProps } from '@/types/common.types';

const UserProfielSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.2398 9.81808C13.7272 9.36763 13.1056 8.97851 12.392 8.66144C12.087 8.52597 11.73 8.66331 11.5946 8.96821C11.4591 9.27312 11.5965 9.63008 11.9014 9.76565C12.503 10.0331 13.0215 10.3561 13.4422 10.7257C13.9609 11.1814 14.2583 11.8413 14.2583 12.5365V13.6542C14.2583 13.9873 13.9873 14.2583 13.6542 14.2583H1.8125C1.47938 14.2583 1.20833 13.9873 1.20833 13.6542V12.5365C1.20833 11.8413 1.50581 11.1814 2.02443 10.7257C2.63485 10.1893 4.41337 8.94165 7.73333 8.94165C10.1985 8.94165 12.2042 6.936 12.2042 4.47083C12.2042 2.00567 10.1985 0 7.73333 0C5.26817 0 3.2625 2.00567 3.2625 4.47083C3.2625 5.912 3.94809 7.19584 5.0101 8.01392C3.06685 8.44112 1.85805 9.26336 1.22686 9.81808C0.447225 10.503 0 11.4938 0 12.5365V13.6542C0 14.6537 0.813029 15.4667 1.8125 15.4667H13.6542C14.6537 15.4667 15.4667 14.6537 15.4667 13.6542V12.5365C15.4667 11.4938 15.0195 10.503 14.2398 9.81808ZM4.47083 4.47083C4.47083 2.6719 5.9344 1.20833 7.73333 1.20833C9.53227 1.20833 10.9958 2.6719 10.9958 4.47083C10.9958 6.26976 9.53227 7.73333 7.73333 7.73333C5.9344 7.73333 4.47083 6.26976 4.47083 4.47083Z" />
    </svg>
  );
};

export default UserProfielSVG;
