import type { ClassNameProps } from '@/types/common.types';

const FavoriteListSVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      enableBackground="new 0 0 512.017 512.017"
      viewBox="0 0 512.017 512.017"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m317.909 114.13c13.675 13.99 15.222 37.698-.78 53.9-.218.242.376-.375-61.31 63.15-64.309-66.238-61.199-63.039-61.31-63.15-16.135-16.544-14.311-40.057-.78-53.9 14.256-14.564 37.353-14.72 51.37-.37 5.877 6.003 15.545 6.021 21.44 0 14.091-14.405 37.169-14.138 51.37.37z" />
      <path d="m440.409 0h-368.8c-8.29 0-15 6.72-15 15v481.67c0 12.415 14.28 19.439 24.11 11.91l175.28-134.1 175.28 134.42c9.825 7.544 24.13.546 24.13-11.9v-482c0-8.238-6.664-15-15-15zm-101.02 188.18-72.81 74.98c-5.919 6.107-15.683 6.022-21.52 0l-72.8-74.97c-41.596-42.587-11.547-115.08 47.52-115.08 13.311 0 25.695 3.926 36.04 10.79 25.263-16.747 60.522-14.277 83.54 9.25 25.61 26.2 25.62 68.82.03 95.03z" />
    </svg>
  );
};

export default FavoriteListSVG;
