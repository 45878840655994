export const phoneFormatter = (phone: string) => {
  const memory = new Map<string, string>();

  if (!memory.has(phone)) {
    const trimmedPhone = ('' + phone).replace(/\D/g, '');
    const matches = trimmedPhone.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
    const result = matches ? '+' + matches.slice(1).join(' ') : '';
    memory.set(phone, result);
    return result;
  }

  return memory.get(phone);
};
