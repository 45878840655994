'use client';

import clsx from 'clsx';

import type { ClassNameProps } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import styles from '@/layout/MainCredentials/MainCredentials.module.scss';

export const MainCredentials = (props: ClassNameProps) => {
  const { className } = props;

  const { userCredentials } = useVerification();

  return (
    <div className={clsx(styles.mainCredentialsBase, className)}>
      <div title={userCredentials?.username} className={styles.login}>
        {userCredentials?.username}
      </div>
      <div className={styles.cineramaId}>{userCredentials?.cineramaId}</div>
    </div>
  );
};
