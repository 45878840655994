import type { SVGProps } from '@/types/common.types';

const NotificationsSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2261 2.25979C12.8499 2.0913 12.4321 2 11.9919 2C10.9019 2 9.9219 2.59 9.4019 3.52C7.4519 4.49 6.1019 6.5 6.1019 8.82V11.35C6.1019 11.76 5.9119 12.46 5.7019 12.82L4.6919 14.49C4.2919 15.16 4.2019 15.9 4.4519 16.58C4.6919 17.25 5.2619 17.77 6.0019 18.02C7.9419 18.68 9.9819 19 12.0219 19C14.0619 19 16.1019 18.68 18.0419 18.03C18.7419 17.8 19.2819 17.27 19.5419 16.58C19.8019 15.89 19.7319 15.13 19.3419 14.49L18.3419 12.83C18.1319 12.46 17.9419 11.76 17.9419 11.35V8.82C17.9419 8.12002 17.8195 7.44843 17.5949 6.82532C17.2505 6.93867 16.8824 7 16.5 7C14.567 7 13 5.433 13 3.5C13 3.0633 13.08 2.64528 13.2261 2.25979Z"
        fill="#9C9C9C"
      />
      <path
        d="M14.8299 20.01C14.4099 21.17 13.2999 22 11.9999 22C11.2099 22 10.4299 21.68 9.87993 21.11C9.55993 20.81 9.31993 20.41 9.17993 20C9.30993 20.02 9.43993 20.03 9.57993 20.05C9.80993 20.08 10.0499 20.11 10.2899 20.13C10.8599 20.18 11.4399 20.21 12.0199 20.21C12.5899 20.21 13.1599 20.18 13.7199 20.13C13.9299 20.11 14.1399 20.1 14.3399 20.07C14.4999 20.05 14.6599 20.03 14.8299 20.01Z"
        fill="#9C9C9C"
      />
      <circle cx="16.5" cy="3.5" r="2.5" fill="#CF4215" />
    </svg>
  );
};

export default NotificationsSVG;
