import type { SVGProps } from '@/types/common.types';

const BurgerSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 20 14"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Side-Menu-360px-[Phone]" transform="translate(-14.000000, -21.000000)">
          <g id="Group-3" transform="translate(14.000000, 18.000000)">
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <g id="Group-2" transform="translate(0.000000, 3.000000)" fill="#FFFFFF">
              <rect id="Rectangle" x="0" y="0" width="20" height="2" rx="1"></rect>
              <rect id="Rectangle-Copy" x="0" y="6" width="18" height="2" rx="1"></rect>
              <rect
                id="Rectangle-Copy-2"
                stroke="#FFFFFF"
                x="0.5"
                y="12.5"
                width="13"
                height="1"
                rx="0.5"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default BurgerSVG;
