import { useTranslations } from 'next-intl';

import { privacyPolicyPath } from '@/app.config';
import AccountSVG from '@/icons/AccountSVG';
import FavoriteListSVG from '@/icons/FavoriteListSVG';
import HistorySVG from '@/icons/HistorySVG';
import PaymentsSVG from '@/icons/PaymentsSVG';
import SessionsSVG from '@/icons/SessionsSVG';
import SubscriptionsSVG from '@/icons/SubscriptionsSVG';

import { NavLink } from '@/components/NavLink';

import styles from '@/layout/UserDropdownMenu/UserDropdownMenu.module.scss';

const links = [
  {
    title: 'header.profile.account',
    href: '/user-profile/account',
    icon: <AccountSVG />,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.subscriptions',
    href: '/user-profile/subscriptions',
    icon: <SubscriptionsSVG />,
    isTasixOnly: true,
  },
  {
    title: 'header.profile.favorites',
    href: '/user-profile/favorites',
    icon: <FavoriteListSVG />,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.history',
    href: '/user-profile/history',
    icon: <HistorySVG />,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.sessions',
    href: '/user-profile/sessions',
    icon: <SessionsSVG />,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.paymentMethod',
    href: '/user-profile/payments',
    icon: <PaymentsSVG />,
    isTasixOnly: true,
  },
  privacyPolicyPath,
];

interface UserProfileLinksProps {
  isTasix: boolean;
  toggleDropdown: () => void;
}

export const UserProfileLinks = (props: UserProfileLinksProps) => {
  const { isTasix, toggleDropdown } = props;

  const t = useTranslations('layout');

  const filteredLinksList = isTasix ? links : links.filter(link => !link.isTasixOnly);

  return (
    <ul className={styles.profileLinks}>
      {filteredLinksList.map(link => (
        <li key={link.title}>
          <NavLink
            href={link.href}
            className={styles.profileLink}
            activeClassName={styles.active}
            onClick={toggleDropdown}
          >
            <span className={styles.profileLinkIcon}>{link.icon}</span>
            <span className={styles.profileLinkTitle}>{t(link.title)}</span>
          </NavLink>
        </li>
      ))}
    </ul>
  );
};
