import { useTranslations } from 'next-intl';

import IpAddressSVG from '@/icons/IpAddressSVG';
import PhoneSVG from '@/icons/PhoneSVG';
import SupportSVG from '@/icons/SupportSVG';

import { phoneFormatter } from '@/helpers/phone-formatter.helpers';

import { InfoLine } from '@/components/InfoLine';
import { SocialProfilesPanel } from '@/components/SocialProfilesPanel';

import styles from '@/layout/MobileMenu/SidebarToggler/SidebarInfoPanel.module.scss';

interface SidebarInfoPanelProps {
  ipAddress?: string;
}

export const SidebarInfoPanel = (props: SidebarInfoPanelProps) => {
  const { ipAddress } = props;

  const t = useTranslations('layout');

  return (
    <div className={styles.sidebarFooter}>
      <InfoLine className={styles.infoLine} as="span">
        <SocialProfilesPanel />
      </InfoLine>
      <InfoLine
        as="a"
        href={process.env.NEXT_PUBLIC_CINERAMA_SUPPORT_TELEGRAM_URL}
        icon={<SupportSVG />}
        className={styles.infoLine}
      >
        @cineramasupport
      </InfoLine>
      <InfoLine
        as="a"
        href={`tel:${process.env.NEXT_PUBLIC_CINERAMA_SUPPORT_PHONE}`}
        icon={<PhoneSVG />}
        className={styles.infoLine}
      >
        {phoneFormatter(process.env.NEXT_PUBLIC_CINERAMA_SUPPORT_PHONE!)}
      </InfoLine>
      {ipAddress && (
        <InfoLine className={styles.infoLine} icon={<IpAddressSVG />} as="span">
          {t('footer.ipAddress', { ipAddress })}
        </InfoLine>
      )}
    </div>
  );
};
