import type { SVGProps } from '@/types/common.types';

const CrossSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="x2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
        <path
          d="M10,8.87226042 L15.6386979,3.23356251 C15.9501146,2.92214583 16.4550208,2.92214583 16.7664375,3.23356251 C17.0778542,3.5449792 17.0778542,4.04988541 16.7664375,4.3613021 L11.1277396,10 L16.7664375,15.6386979 C17.0778542,15.9501146 17.0778542,16.4550208 16.7664375,16.7664375 C16.4550208,17.0778542 15.9501146,17.0778542 15.6386979,16.7664375 L10,11.1277396 L4.3613021,16.7664375 C4.04988541,17.0778542 3.5449792,17.0778542 3.23356251,16.7664375 C2.92214583,16.4550208 2.92214583,15.9501146 3.23356251,15.6386979 L8.87226042,10 L3.23356251,4.3613021 C2.92214583,4.04988541 2.92214583,3.5449792 3.23356251,3.23356251 C3.5449792,2.92214583 4.04988541,2.92214583 4.3613021,3.23356251 L10,8.87226042 Z"
          id="Combined-Shape"
          fill="currentColor"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
};

export default CrossSVG;
