import type { ElementType, ReactNode, ComponentPropsWithRef } from 'react';

import cn from 'classnames';

import styles from '@/components/InfoLine/InfoLine.module.scss';

// =================================================================

type InfoLineProps<E extends ElementType> = {
  as?: E;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  className?: string;
  children?: ReactNode;
} & ComponentPropsWithRef<E>;

// =================================================================

export const InfoLine = <E extends ElementType = 'a'>({
  as: Element = 'a',
  icon,
  rightIcon,
  className,
  children,
  ...rest
}: InfoLineProps<E>) => {
  return (
    <Element
      {...rest}
      className={cn({
        [styles.infoLineBase]: true,
        [styles.hoverable]: Element === 'a',
        className,
      })}
    >
      {icon}
      {children}
      {rightIcon}
    </Element>
  );
};
