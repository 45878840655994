'use client';

import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';

import ChevronDownSVG from '@/icons/ChevronDownSVG';

import { Dropdown } from '@/components/Dropdown/Dropdown';
import { NavLink } from '@/components/NavLink';

import styles from '@/layout/DesktopMenu/ExtraNavDropDown.module.scss';

// =================================================================

type ExtraNavDropDownProps = {
  options: {
    id: string;
    title: string;
    moduleType: string;
  }[];
};
// =================================================================

export const ExtraNavDropDown = (props: ExtraNavDropDownProps) => {
  const { options } = props;
  const t = useTranslations();
  const pathname = usePathname();

  const isActiveExtra = !!options.find(option =>
    pathname.includes(`/${option.moduleType}/${option.id}`),
  );

  const labelClassNames = cn({
    [styles.label]: true,
    [styles.active]: isActiveExtra,
  });

  return (
    <Dropdown trigger="click">
      <Dropdown.Label
        iconWidth={12}
        iconHeight={6}
        className={labelClassNames}
        icon={ChevronDownSVG}
      >
        {t('common.extra')}
      </Dropdown.Label>
      <Dropdown.Menu dropdownClassName={styles.dropdown}>
        {options.map(option => (
          <NavLink
            key={option.id}
            href={`/${option.moduleType}/${option.id}`}
            className={styles.navLink}
            activeClassName={styles.active}
          >
            <Dropdown.Option className={styles.option} value={option.id}>
              {t('layout.' + option.title)}
            </Dropdown.Option>
          </NavLink>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
