import { useTransition } from 'react';

import clsx from 'clsx';
import { useLocale } from 'next-intl';

import { useRouter, usePathname } from '@/navigation';
import type { ClassNameProps } from '@/types/common.types';

import { LocalesEnum } from '@/enums/locales.enums';

import styles from '@/layout/Locales/LocalesMobile.module.scss';

interface LocalesMobileProps extends ClassNameProps {
  onClick?: () => void;
}

export const LocalesMobile = (props: LocalesMobileProps) => {
  const { onClick, className } = props;

  const defaultLocale = useLocale();

  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();

  const handleSwitchLocale = (locale: LocalesEnum) => {
    startTransition(() => {
      router.replace(pathname, { locale });
      if (typeof onClick === 'function') {
        onClick();
      }
    });
  };

  return (
    <ul className={clsx(styles.languages, className)}>
      {Object.keys(LocalesEnum).map(key => (
        <li
          key={key}
          className={clsx({
            [styles.language]: true,
            [styles.active]: defaultLocale === LocalesEnum[key as keyof typeof LocalesEnum],
          })}
        >
          <button
            disabled={isPending}
            onClick={() => handleSwitchLocale(LocalesEnum[key as keyof typeof LocalesEnum])}
          >
            {LocalesEnum[key as keyof typeof LocalesEnum]}
          </button>
        </li>
      ))}
    </ul>
  );
};
