import { useEffect, useMemo, useState, useRef } from 'react';

import debounce from 'lodash/debounce';

import { MovieApi } from '@/api/domains/movie.api';
import { Movie, MovieList } from '@/api/types/movie.types';

const SUGGESTED_OPTIONS_LIMIT = 3;

export const useDebouncedSearch = (query: string): [boolean, MovieList[] | null] => {
  const [movies, setMovies] = useState<MovieList[] | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const shouldReceiveData = useRef(false);

  const debouncedHandleSearch = useMemo(() => {
    return debounce(async (trimmedQuery: string) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const { list } = await MovieApi.fetchSearchedByQuery({
          query: trimmedQuery,
          items: SUGGESTED_OPTIONS_LIMIT,
        });

        if (shouldReceiveData.current) {
          setMovies(list);
          setIsFetching(false);
        }
      } catch (error) {
        throw error;
      }
    }, 500);
  }, []);

  useEffect(() => {
    shouldReceiveData.current = true;

    const trimmedQuery = query.trim();
    if (trimmedQuery) {
      setIsFetching(true);
      debouncedHandleSearch(trimmedQuery);
    } else {
      shouldReceiveData.current = false;
      setIsFetching(false);
      setMovies(null);
    }

    return () => {
      shouldReceiveData.current = false;
    };
  }, [query, debouncedHandleSearch]);

  return [isFetching, movies];
};
