'use client';

import { useTranslations } from 'next-intl';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { SolidButton } from '@/components/Buttons';

interface LoginButtonProps {
  onClick?: () => void;
}

export const LoginButton = (props: LoginButtonProps) => {
  const t = useTranslations('verification');
  const { onClick } = props;

  const { checkAuthVerification, closeVerificationModal } = useVerification();

  const handleClick = checkAuthVerification({
    onSuccess: () => {
      onClick?.();
      closeVerificationModal();
    },
  });

  return (
    <SolidButton as="button" onClick={handleClick as () => void}>
      {t('toComeIn')}
    </SolidButton>
  );
};
