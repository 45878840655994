import type { SVGProps } from '@/types/common.types';

const OnlineTvSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      fill="currentColor"
      {...props}
    >
      <g>
        <g>
          <path d="m90 252h272v170h-272z" />
          <path d="m457 162h-402c-30.327 0-55 24.673-55 55v240c0 30.327 24.673 55 55 55h402c30.327 0 55-24.673 55-55v-240c0-30.327-24.673-55-55-55zm-65 275c0 8.284-6.716 15-15 15h-302c-8.284 0-15-6.716-15-15v-200c0-8.284 6.716-15 15-15h302c8.284 0 15 6.716 15 15zm45 15c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15s6.716-15 15-15 15 6.716 15 15-6.716 15-15 15zm15-75c0 8.284-6.716 15-15 15s-15-6.716-15-15v-20c0-8.284 6.716-15 15-15s15 6.716 15 15z" />
          <path d="m208.864 116.028c-6.764 4.438-12.874 9.812-18.135 15.972h130.543c-5.261-6.16-11.372-11.535-18.136-15.973l46.33-94.416c3.649-7.437.579-16.425-6.858-20.074-7.437-3.648-16.425-.579-20.073 6.858l-47.021 95.826c-6.281-1.452-12.814-2.221-19.514-2.221s-13.233.769-19.513 2.221l-47.021-95.825c-3.648-7.438-12.641-10.507-20.073-6.858-7.438 3.649-10.508 12.637-6.858 20.074z" />
        </g>
      </g>
    </svg>
  );
};

export default OnlineTvSVG;
