'use client';

import { Fragment, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useKey } from 'react-use';

import { isEmpty } from 'lodash';

import { useRouter } from '@/navigation';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { useDebouncedSearch } from '@/layout/SearchBar/hooks/useDebouncedSearch';

import { Form } from '@/components/Form';
import { Spinner } from '@/components/Spinner';

import styles from '@/layout/SearchBar/SearchForm.module.scss';
import { SuggestedDropdown } from '@/layout/SearchBar/SuggestedDropdown';

// =================================================================

interface SearchFromProps {
  toggleSearchBar: () => void;
}

// =================================================================

export const SearchFrom = (props: SearchFromProps) => {
  const { toggleSearchBar } = props;
  useLockBodyScroll();
  const router = useRouter();

  const [indexOfHighlightedElement, setIndexOfHighlightedElement] = useState<number | null>(null);

  useKey('Escape', toggleSearchBar);

  const methods = useForm<{ search: string }>({
    defaultValues: {
      search: '',
    },
  });
  const [isFetching, movies] = useDebouncedSearch(methods.watch('search'));

  useEffect(() => {
    methods.setFocus('search');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (isFetching || isEmpty(movies)) {
      return;
    }

    if (indexOfHighlightedElement && movies && movies[indexOfHighlightedElement]) {
      const { moduleId, id } = movies[indexOfHighlightedElement];

      router.push(`/watch/${moduleId}/${id}`);
    } else {
      router.push(`/search?query=${methods.getValues('search')}`);
    }

    toggleSearchBar();
  };

  return (
    <Fragment>
      <div className={styles.searchFormWrapper}>
        <FormProvider {...methods}>
          <Form
            isSubmitting={methods.formState.isSubmitting}
            className={styles.searchForm}
            onSubmit={methods.handleSubmit(handleSubmit)}
          >
            <Form.Input
              name="search"
              label={'Поиск'}
              errorClassName={styles.errorMessage}
              type="text"
              className={styles.searchInput}
            />
            {isFetching && <Spinner className={styles.spinner} />}
          </Form>
        </FormProvider>
      </div>
      {movies && methods.watch('search') && (
        <SuggestedDropdown
          movies={movies}
          toggleSearchBar={toggleSearchBar}
          query={methods.getValues('search')}
          indexOfHighlightedElement={Number(indexOfHighlightedElement)}
          setIndexOfHighlightedElement={setIndexOfHighlightedElement}
        />
      )}
    </Fragment>
  );
};
