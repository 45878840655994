'use client';

import { Fragment } from 'react';
import { useMedia } from 'react-use';

import { breakpoints } from '@/app.config';
import type { ChildrenProps } from '@/types/common.types';

import { InfoLine } from '@/components/InfoLine';
import { SocialProfilesPanel } from '@/components/SocialProfilesPanel';

import styles from '@/layout/MainFooter/MainFooter.module.scss';

const currentYear = process.env.CURRENT_YEAR;

const MainFooterInfo = (props: ChildrenProps) => {
  const { children } = props;

  const isExtraLarge = useMedia(breakpoints.extraLarge, true);

  return (
    <Fragment>
      {isExtraLarge && children}
      <div className={styles.infoColumn}>
        {isExtraLarge && (
          <InfoLine className={styles.infoLine} as="div">
            <SocialProfilesPanel />
          </InfoLine>
        )}
        <InfoLine className={styles.infoLine} as="a" href="//tn.uz">
          <span>&copy; 2011-{currentYear}. </span>
          <span>Turon Telecom</span>
        </InfoLine>
      </div>
    </Fragment>
  );
};

export default MainFooterInfo;
