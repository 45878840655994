'use client';

import { useTransition } from 'react';

import clsx from 'clsx';
import { useLocale, useTranslations } from 'next-intl';

import TickCircleSVG from '@/icons/TickCircleSVG';
import { useRouter, usePathname } from '@/navigation';
import type { ClassNameProps } from '@/types/common.types';

import { httpClient } from '@/libs/http-client';

import { LocalesEnum } from '@/enums/locales.enums';

import styles from '@/layout/Locales/Locales.module.scss';

interface LocalesProps extends ClassNameProps {
  onClick?: () => void;
}

export const Locales = (props: LocalesProps) => {
  const { onClick, className } = props;

  const defaultLocale = useLocale();
  const t = useTranslations('languages');

  const router = useRouter();
  const [isPending, startTransition] = useTransition();
  const pathname = usePathname();

  const handleSwitchLocale = (locale: LocalesEnum) => {
    startTransition(() => {
      httpClient.defaults.headers['Locale'] = locale;

      router.replace(pathname, { locale });
      if (typeof onClick === 'function') {
        onClick();
      }
    });
  };

  return (
    <ul className={clsx(styles.languages, className)}>
      {Object.keys(LocalesEnum).map(key => (
        <li
          key={key}
          className={clsx({
            [styles.language]: true,
            [styles.active]: defaultLocale === LocalesEnum[key as keyof typeof LocalesEnum],
          })}
        >
          <button
            disabled={isPending}
            onClick={() => handleSwitchLocale(LocalesEnum[key as keyof typeof LocalesEnum])}
          >
            {t(LocalesEnum[key as keyof typeof LocalesEnum])}
            {defaultLocale === LocalesEnum[key as keyof typeof LocalesEnum] && <TickCircleSVG />}
          </button>
        </li>
      ))}
    </ul>
  );
};
