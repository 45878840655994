import type { SVGProps } from '@/types/common.types';

const UzbekKinoSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="currentColor"
      viewBox="0 0 21.118 21.119"
      {...props}
    >
      <g>
        <g>
          <path d="M0.668,10.883c0.308-0.053,0.696,0.269,0.869-0.402C1.84,9.304,3.5,8.628,4.38,9.458c0.858,0.808,1.442,1.824,2.823,1.689   c0.072-0.007,0.209,0.204,0.244,0.333c0.422,1.601,1.867,2.114,3.013,2.998c0.895,0.688,2.398,0.502,2.803,1.974   c0.05,0.185,1.033,0.817,1.634-0.215c0.697-1.199,0.296-1.912-1.068-2.89c1.644,0.379,2.146-0.473,2.74-1.204   c0.501-0.619,0.781-0.523,1.274,0.056c0.184,0.217,0.479,0.811,0.999,0.497c0.707-0.423,1.634-0.405,2.276-1.146   c-0.647-0.35-1.244-0.864-1.881-0.923c-0.314-0.028-0.77,0.361-1.068-0.074c-0.43-0.629,0.627-0.627,0.598-1.256   c-1.167,0.305-2.073,0.903-2.902,1.647c-0.21,0.19-0.584,0.552-0.63,0.517c-1.02-0.807-2.819-0.774-2.754-2.813   c0.017-0.517-0.732-1.437-1.867-1.369C9.034,7.371,7.505,7.682,6.109,6.095C4.835,4.646,2.82,4.067,0.73,4.394   C0.295,4.46-0.008,4.466,0,5.027c0.025,1.771,0.019,3.54,0.01,5.312C0.009,10.815,0.279,10.945,0.668,10.883z" />
        </g>
      </g>
    </svg>
  );
};

export default UzbekKinoSVG;
