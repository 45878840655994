import type { ClassNameProps } from '@/types/common.types';

const IpAddressSVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.18805 3.97259H5.49176V9.71853H4.18805V3.97259ZM8.37526 3.92966C7.58242 3.92966 7.0202 3.981 6.61873 4.05002V9.71853H7.90646V7.66406C8.02681 7.68089 8.17915 7.69015 8.35001 7.69015C9.11675 7.69015 9.77324 7.50162 10.2168 7.08332C10.5577 6.75845 10.7453 6.28207 10.7453 5.71901C10.7453 5.15679 10.4971 4.68041 10.1318 4.39004C9.74799 4.08284 9.17566 3.92966 8.37526 3.92966ZM8.33991 6.69111C8.15306 6.69111 8.01587 6.6827 7.90561 6.65661V4.96152C7.99904 4.93627 8.17831 4.91018 8.44343 4.91018C9.09066 4.91018 9.45762 5.2258 9.45762 5.75352C9.45762 6.34183 9.0309 6.69111 8.33991 6.69111ZM7 0C3.13346 0 0 3.13346 0 6.99916C0 10.8649 7 17.0299 7 17.0299C7 17.0299 14 10.8657 14 7C14 3.1343 10.8665 0 7 0ZM7.01767 11.2554C4.45064 11.2554 2.37009 9.17398 2.37009 6.60611C2.37009 4.03908 4.45148 1.95852 7.01767 1.95852C9.58555 1.95852 11.6653 4.03992 11.6653 6.60611C11.6653 9.17314 9.58471 11.2554 7.01767 11.2554Z"
        fill="white"
      />
    </svg>
  );
};

export default IpAddressSVG;
