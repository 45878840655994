import { Fragment } from 'react';

import { Backdrop } from '@/components/Backdrop';

import styles from '@/layout/SearchBar/SearchBar.module.scss';
import { SearchFrom } from '@/layout/SearchBar/SearchForm';

// =================================================================

interface SearchBarProps {
  toggleSearchBar: () => void;
}

// =================================================================

export const SearchBar = (props: SearchBarProps) => {
  const { toggleSearchBar } = props;
  return (
    <Fragment>
      <Backdrop className={styles.backdrop} onClick={toggleSearchBar} />
      <div className={styles.searchBar}>
        <SearchFrom toggleSearchBar={toggleSearchBar} />
      </div>
    </Fragment>
  );
};
