'use client';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { useDropdownContext } from '@/components/Dropdown/contexts/useDropdownContext';
import { useClickOutside } from '@/hooks/useClickOutside';

import { FadeTransition } from '@/components/Animations';
import styles from '@/components/Dropdown/DropdownMenu.module.scss';

// =================================================================

type DropdownMenuProps = ChildrenProps &
  ClassNameProps & {
    dropdownClassName?: string;
  };

// =================================================================

export const DropdownMenu = (props: DropdownMenuProps) => {
  const { children, className, dropdownClassName } = props;

  const { toggleDropdown, isDropdownVisible } = useDropdownContext();

  const dropdownRef = useClickOutside<HTMLDivElement>(toggleDropdown);

  return (
    <FadeTransition inProp={isDropdownVisible}>
      <div ref={dropdownRef} className={clsx(styles.dropdown, dropdownClassName)}>
        <div className={styles.wrapper}>
          <ul className={clsx(styles.menu, className)}>{children}</ul>
        </div>
      </div>
    </FadeTransition>
  );
};
