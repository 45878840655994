'use client';

import { ChildrenProps } from '@/types/common.types';

import { useClickOutside } from '@/hooks/useClickOutside';

import styles from '@/layout/LanguageDropdown/LanguageDropdown.module.scss';

// =================================================================

interface DropdownProps extends ChildrenProps {
  toggleDropdown: () => void;
}

// =================================================================

export const Dropdown = (props: DropdownProps) => {
  const { toggleDropdown, children } = props;

  const dropdownRef = useClickOutside<HTMLDivElement>(toggleDropdown);

  return (
    <div ref={dropdownRef} className={styles.dropdown}>
      {children}
    </div>
  );
};
