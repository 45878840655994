import type { ClassNameProps } from '@/types/common.types';

const HistorySVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m57.443 0c-26.124 0-48.349 16.696-56.585 40-1.623 4.592-.916 9.685 1.897 13.661s7.379 6.339 12.249 6.339h102.439c0-33.137-26.863-60-60-60z" />
      <path d="m278.543 452c-3.398 23.445-14.724 44.2-30.87 60h197.387c27.695 0 51.929-15.093 64.916-37.486 2.692-4.642 2.701-10.368.02-15.017-2.681-4.648-7.639-7.498-13.005-7.498h-218.448z" />
      <path d="m385.06 0h-261.025c10.143 11.247 39.333 36.376 13.976 166.862-16.613 89.616-51.067 182.297-50.261 273.183 1.638 39.915 34.383 71.955 74.693 71.955 41.353 0 75-33.647 75-75 0-8.291 6.709-15 15-15h147.924c2.227-54.126 15.938-108.545 29.238-161.338 15.181-60.281 30.455-123.225 30.455-185.662 0-41.353-33.648-75-75-75zm-30.162 330h-189.752c-8.284 0-15-6.716-15-15s6.716-15 15-15h189.752c8.284 0 15 6.716 15 15s-6.715 15-15 15zm14.261-60h-189.356c-8.284 0-15-6.716-15-15s6.716-15 15-15h189.355c8.284 0 15 6.716 15 15s-6.715 15-14.999 15zm14.657-60h-190.184c-8.284 0-15-6.716-15-15s6.716-15 15-15h190.184c8.284 0 15 6.716 15 15s-6.715 15-15 15zm11.443-60h-191.245c-8.284 0-15-6.716-15-15s6.716-15 15-15h191.246c8.284 0 15 6.716 15 15s-6.717 15-15.001 15z" />
    </svg>
  );
};

export default HistorySVG;
