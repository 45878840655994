import type { SVGProps } from '@/types/common.types';

const SeriesSVG = (props: SVGProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M17.5724 4.30664C17.3292 4.30664 17.1391 4.50375 17.1391 4.74515C17.1391 6.84101 13.4168 8.6126 9.00762 8.6126C4.59839 8.6126 0.874996 6.84101 0.874996 4.74515C0.874996 4.50375 0.69126 4.30664 0.448106 4.30664C0.206705 4.30664 0.0209961 4.50375 0.0209961 4.74515V12.6469C0.0209961 15.3065 3.95882 17.3894 9.01047 17.3894C14.0621 17.3894 17.9999 15.3065 17.9999 12.6469V4.74515C17.9999 4.50375 17.8136 4.30664 17.5724 4.30664ZM9.22534 13.5154H9.00828C8.52811 13.5154 8.13345 13.2367 8.13345 12.7587V11.4544C8.13345 10.972 8.52811 10.665 9.00828 10.665H9.22534C9.70551 10.665 10.1067 10.9718 10.1067 11.4544V12.7587C10.1067 13.2367 9.70551 13.5154 9.22534 13.5154ZM12.1064 10.8205C12.0998 10.3272 12.444 9.8799 12.8825 9.82749L13.0777 9.80535C13.514 9.75053 13.8714 10.1057 13.8823 10.5949L13.9045 11.9323C13.9108 12.4279 13.5644 12.8708 13.1305 12.9234L12.9332 12.9475C12.4971 13.0045 12.1373 12.6493 12.1285 12.1582L12.1064 10.8205ZM15.5881 10.5949V9.60166C15.5881 9.04475 15.8655 8.50538 16.1768 8.39795L16.3299 8.34971C16.6366 8.24228 16.8951 8.61041 16.8929 9.16732L16.8938 10.1627C16.8938 10.7221 16.6456 11.2614 16.3365 11.3664L16.1716 11.4125C15.8602 11.5199 15.5881 11.1538 15.5881 10.5949ZM6.12507 10.8205L6.10534 12.1582C6.09657 12.6491 5.73699 13.0043 5.30067 12.9475L5.10334 12.9234C4.66724 12.871 4.32082 12.4279 4.32959 11.9323L4.35151 10.5949C4.36028 10.1059 4.71767 9.75053 5.15399 9.80535L5.3511 9.82749C5.78742 9.8799 6.13603 10.3272 6.12507 10.8205ZM1.11727 9.16732C1.11727 8.61041 1.36942 8.24206 1.67879 8.34971L1.84323 8.39795C2.15457 8.50538 2.43281 9.04475 2.43281 9.60166V10.5949C2.43281 11.154 2.15764 11.5201 1.8509 11.4125L1.69567 11.3664C1.38652 11.2614 1.11727 10.7221 1.11727 10.1627V9.16732Z" />
      <path d="M0.438511 5.1775C0.196453 5.1775 0 4.98105 0 4.73899C0 2.08161 3.958 0 9.01052 0C12.0801 0 14.9008 0.791951 16.5566 2.11867C16.7454 2.26995 16.7759 2.54599 16.6246 2.73499C16.4733 2.92443 16.1971 2.95447 16.0081 2.80296C14.5255 1.61504 11.8442 0.876803 9.01052 0.876803C4.60173 0.876803 0.877022 2.64532 0.877022 4.73877C0.877022 4.98105 0.680788 5.1775 0.438511 5.1775Z" />
      <path d="M9.0105 6.15918C6.31717 6.15918 3.94417 6.76608 2.34229 7.73848C2.5679 7.97768 2.7797 8.19957 3.01299 8.36884C4.47937 7.55913 6.64298 7.0362 9.0105 7.0362C11.3835 7.0362 13.5515 7.56154 15.0179 8.37432C15.3321 8.22785 15.6675 8.09411 15.9133 7.88735C14.3141 6.82813 11.8387 6.15918 9.0105 6.15918Z" />
      <path d="M9.08969 4.85128H8.91012C8.27428 4.85128 7.75684 4.33318 7.75684 3.69603V2.62496C7.75684 1.98912 8.27406 1.47168 8.91012 1.47168H9.08969C9.72553 1.47168 10.243 1.9889 10.243 2.62496V3.69625C10.243 4.33318 9.72575 4.85128 9.08969 4.85128ZM8.91012 2.3487C8.75796 2.3487 8.63386 2.47258 8.63386 2.62496V3.69625C8.63386 3.84972 8.75774 3.97448 8.91012 3.97448H9.08969C9.24185 3.97448 9.36595 3.84972 9.36595 3.69625V2.62496C9.36595 2.47258 9.24207 2.3487 9.08969 2.3487H8.91012V2.3487Z" />
      <path d="M5.67102 5.49518C5.10622 5.49518 4.65521 5.01808 4.64425 4.40899L4.62824 3.30657C4.61443 2.67885 5.06193 2.1261 5.6469 2.05156L5.8063 2.03116C6.43885 1.94938 6.95191 2.45433 6.96331 3.10924L6.98129 4.20903C6.99269 4.84114 6.54431 5.39323 5.96066 5.46493L5.80345 5.48663C5.75521 5.49277 5.71268 5.49518 5.67102 5.49518ZM5.93588 2.89985L5.75828 2.92156C5.63506 2.93735 5.50088 3.0985 5.50526 3.29057L5.52127 4.39496C5.52368 4.5309 5.59713 4.62825 5.68944 4.61728L5.84752 4.59536C5.97513 4.57957 6.108 4.4182 6.10449 4.22438L6.08651 3.12437C6.0841 2.99655 6.01942 2.89985 5.93588 2.89985Z" />
      <path d="M15.0249 6.70006C14.9489 6.70006 14.8728 6.68735 14.7985 6.66213L14.6783 6.62003C14.1848 6.44858 13.9256 5.78467 13.9256 5.21768L13.9219 4.40336C13.9219 3.7377 14.2558 3.27441 14.7342 3.27441C14.8112 3.27441 14.8879 3.28757 14.9627 3.31322L15.0833 3.35576C15.5764 3.52371 15.8371 4.18805 15.8371 4.7568V5.57331C15.8371 6.23678 15.5029 6.70006 15.0249 6.70006ZM14.8162 4.2216C14.8064 4.26764 14.7989 4.32728 14.7989 4.40139L14.8026 5.2157C14.8026 5.46631 14.8833 5.66035 14.9436 5.75047C14.953 5.70486 14.96 5.64588 14.96 5.57309V4.75658C14.96 4.50531 14.877 4.31018 14.8162 4.2216Z" />
      <path d="M2.80187 6.69979C2.32367 6.69979 1.98975 6.23651 1.98975 5.57304V4.75653C1.99216 4.18603 2.25351 3.52234 2.74859 3.35373L2.86019 3.31405C2.93868 3.28686 3.01564 3.27393 3.0926 3.27393C3.57102 3.27393 3.90494 3.73743 3.90494 4.40112L3.90122 5.21916C3.90122 5.78418 3.64206 6.44787 3.14698 6.61998L3.03362 6.65989C2.95403 6.68708 2.87773 6.69979 2.80187 6.69979ZM3.0106 4.22177C2.95052 4.31079 2.86786 4.50636 2.86677 4.7585V5.57304C2.86677 5.64956 2.87466 5.71073 2.88475 5.75743C2.93605 5.6774 3.02419 5.47766 3.02419 5.21741L3.02792 4.39936C3.0277 4.32657 3.02047 4.26759 3.0106 4.22177Z" />
      <path d="M12.3302 5.49536C12.33 5.49536 12.33 5.49536 12.3302 5.49536C12.2883 5.49536 12.2458 5.49273 12.2028 5.48725L12.0355 5.46401C11.458 5.39363 11.0088 4.84176 11.0197 4.20855L11.0397 3.10899C11.0485 2.45517 11.5681 1.95044 12.193 2.03091L12.3554 2.0513C12.9356 2.12562 13.3833 2.67683 13.3748 3.30588L13.357 4.40807C13.3434 5.01914 12.8924 5.49536 12.3302 5.49536ZM12.0805 2.90135C11.9807 2.90135 11.9185 2.99673 11.9167 3.12302L11.8967 4.22434C11.8932 4.41794 12.0272 4.57975 12.1489 4.59444L12.33 4.61834C12.4127 4.61834 12.4771 4.52077 12.4802 4.39163L12.4977 3.29338C12.5004 3.10022 12.3662 2.93731 12.2449 2.92174L12.0825 2.90135C12.0818 2.90135 12.0811 2.90135 12.0805 2.90135Z" />
    </svg>
  );
};

export default SeriesSVG;
