import React from 'react';

import cn from 'classnames';

import { Link } from '@/navigation';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from '@/components/Avatars/AvatarBase.module.scss';

// =================================================================

type CommonProps = {
  href?: string;
  variant?: 'dimmed' | 'light' | 'dark' | 'signature';
  initials?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
} & ClassNameProps &
  Partial<ChildrenProps>;

export type AvatarBaseProps = CommonProps &
  (
    | {
        as: 'button';
        type?: 'button' | 'submit' | 'reset';
        href?: never;
        replace?: never;
      }
    | {
        as: 'link';
        type?: never;
        href: string;
        replace?: boolean;
      }
  );

// =================================================================

export const AvatarBase = (props: AvatarBaseProps) => {
  const { as = 'button', href, variant = 'dimmed', className, initials, ...rest } = props;

  if (as === 'link' && href) {
    return (
      <Link
        href={href}
        prefetch={false}
        className={cn(styles.avatarBase, styles[variant], className)}
        {...rest}
      >
        {initials}
      </Link>
    );
  }

  return (
    <button className={cn(styles.avatarBase, styles[variant], className)} {...rest}>
      {initials}
    </button>
  );
};

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  AvatarBase.displayName = 'AvatarBase';
}
