import type { SVGProps } from '@/types/common.types';

const RatingKinopoiskSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="INDEX-2-Copy" transform="translate(-812.000000, -718.000000)" fill="#FF791A">
          <g id="ico_kinopoisk" transform="translate(812.000000, 718.000000)">
            <path d="M10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 Z M10,11.3333333 C10.7363797,11.3333333 11.3333333,10.7363797 11.3333333,10 C11.3333333,9.26362033 10.7363797,8.66666667 10,8.66666667 C9.26362033,8.66666667 8.66666667,9.26362033 8.66666667,10 C8.66666667,10.7363797 9.26362033,11.3333333 10,11.3333333 Z M6,8.66666667 C7.47275933,8.66666667 8.66666667,7.47275933 8.66666667,6 C8.66666667,4.52724067 7.47275933,3.33333333 6,3.33333333 C4.52724067,3.33333333 3.33333333,4.52724067 3.33333333,6 C3.33333333,7.47275933 4.52724067,8.66666667 6,8.66666667 Z M6,16.6666667 C7.47275933,16.6666667 8.66666667,15.4727593 8.66666667,14 C8.66666667,12.5272407 7.47275933,11.3333333 6,11.3333333 C4.52724067,11.3333333 3.33333333,12.5272407 3.33333333,14 C3.33333333,15.4727593 4.52724067,16.6666667 6,16.6666667 Z M14,8.66666667 C15.4727593,8.66666667 16.6666667,7.47275933 16.6666667,6 C16.6666667,4.52724067 15.4727593,3.33333333 14,3.33333333 C12.5272407,3.33333333 11.3333333,4.52724067 11.3333333,6 C11.3333333,7.47275933 12.5272407,8.66666667 14,8.66666667 Z M14,16.6666667 C15.4727593,16.6666667 16.6666667,15.4727593 16.6666667,14 C16.6666667,12.5272407 15.4727593,11.3333333 14,11.3333333 C12.5272407,11.3333333 11.3333333,12.5272407 11.3333333,14 C11.3333333,15.4727593 12.5272407,16.6666667 14,16.6666667 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RatingKinopoiskSVG;
