'use client';

import { Fragment } from 'react';

import { Logo } from '@/components/Logo';

import styles from '@/layout/MobileMenu/SelectedMenuMobile.module.scss';
import { SearchBar } from '@/layout/SearchBar/SearchBar';

import { useStore } from '@/contexts/StoreContext';

export const SelectedMenuMobile = () => {
  const { isSearchBarVisible, toggleSearchBar } = useStore(state => state);
  return (
    <Fragment>
      {isSearchBarVisible ? (
        <SearchBar toggleSearchBar={toggleSearchBar} />
      ) : (
        <Logo className={styles.mobileHeaderLogo} />
      )}
    </Fragment>
  );
};
