import React, { createContext, useContext } from 'react';

import { ChildrenProps } from '@/types/common.types';

// =================================================================

export type DropdownContextType = {
  value?: number | string;
  isDropdownVisible: boolean;
  toggleDropdown: () => void;
  onChange?: (value: string | number) => void;
  trigger: 'click' | 'hover';
};

// =================================================================

const DropdownContext = createContext<DropdownContextType | null>(null);

// =================================================================

export const DropdownProvider = (props: DropdownContextType & ChildrenProps) => {
  const { value, children, isDropdownVisible, toggleDropdown, onChange, trigger } = props;

  return (
    <DropdownContext.Provider
      value={{
        value,
        isDropdownVisible,
        toggleDropdown,
        onChange,
        trigger,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

// =================================================================

export const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (!context) {
    throw new Error('useDropdownContext must be used within a DropdownContext');
  }

  return context;
};
