'use client';

import { Fragment } from 'react';

import { SidebarInfoPanel } from './SidebarInfoPanel';

import BurgerSVG from '@/icons/BurgerSVG';

import { getInitialsFromName } from '@/helpers/transformations.helpers';

import { FadeTransition, SlideTransition } from '@/components/Animations';
import { Backdrop } from '@/components/Backdrop';
import { IconButton } from '@/components/Buttons';
import { Portal } from '@/components/Portal';

import { MainCredentials } from '@/layout/MainCredentials';
import { Sidebar } from '@/layout/MobileMenu/SidebarToggler/Sidebar';
import { SidebarNavPanel } from '@/layout/MobileMenu/SidebarToggler/SidebarNavPanel';
import styles from '@/layout/MobileMenu/SidebarToggler/SidebarToggler.module.scss';
import { SidebarUserPanel } from '@/layout/MobileMenu/SidebarToggler/SidebarUserPanel';

import { useStore } from '@/contexts/StoreContext';

export const SidebarToggler = () => {
  const { hideSidebar, isSidebarVisible, showSidebar, verificationStore } = useStore(
    state => state,
  );

  const userCredentials = verificationStore.userCredentials;
  const username = userCredentials?.fullName || userCredentials?.username;

  const initials = getInitialsFromName(username || '');

  return (
    <Fragment>
      <IconButton
        as="button"
        icon={BurgerSVG}
        iconWidth={20}
        iconHeight={20}
        className={styles.sidebarToggler}
        onClick={showSidebar}
      />
      <Portal>
        <FadeTransition inProp={isSidebarVisible}>
          <Backdrop onClick={hideSidebar} />
        </FadeTransition>
        <SlideTransition inProp={isSidebarVisible}>
          <Sidebar>
            <SidebarUserPanel initials={initials} isAuthenticated={Boolean(userCredentials)}>
              {userCredentials && <MainCredentials className={styles.mainCredentials} />}
            </SidebarUserPanel>
            <SidebarNavPanel hideSidebar={hideSidebar} />
            <SidebarInfoPanel ipAddress={userCredentials?.clientIp} />
          </Sidebar>
        </SlideTransition>
      </Portal>
    </Fragment>
  );
};
