'use client';

import NotificationsSVG from '@/icons/NotificationsSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { IconButton } from '@/components/Buttons';

import { LoginButton } from '@/layout/DesktopMenu/LoginButton';
import { SearchButton } from '@/layout/DesktopMenu/SearchButton';
import styles from '@/layout/DesktopMenu/SecondaryNav.module.scss';
import { LanguageDropdown } from '@/layout/LanguageDropdown';
import { UserDropdownMenu } from '@/layout/UserDropdownMenu';

export const SecondaryNav = () => {
  const { userAccessToken } = useVerification();

  return (
    <div className={styles.secondaryNav}>
      <LanguageDropdown />
      <SearchButton />
      <IconButton
        as="link"
        href="/notifications"
        icon={NotificationsSVG}
        iconWidth={25}
        iconHeight={25}
        className={styles.notificationsIcon}
      />
      {userAccessToken && <UserDropdownMenu />}
      {!userAccessToken && <LoginButton />}
    </div>
  );
};
