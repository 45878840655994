'use client';

import FocusTrap from 'focus-trap-react';
import { useState } from 'react';
import { useKey } from 'react-use';

import { useLocale, useTranslations } from 'next-intl';

import GlobusSVG from '@/icons/GlobusSVG';

import { FadeTransition } from '@/components/Animations';
import { IconButton } from '@/components/Buttons';
import { Focusable } from '@/components/Focusable';

import { Dropdown } from '@/layout/LanguageDropdown/Dropdown';
import styles from '@/layout/LanguageDropdown/LanguageDropdown.module.scss';
import { Locales } from '@/layout/Locales';

// =================================================================

export const LanguageDropdown = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleDropdown = () => setIsDropdownVisible(isDropdownVisible => !isDropdownVisible);

  const defaultLocale = useLocale();

  const t = useTranslations('languages');

  useKey('Escape', () => setIsDropdownVisible(false));

  return (
    <FocusTrap
      active={isDropdownVisible}
      focusTrapOptions={{ allowOutsideClick: true, escapeDeactivates: false }}
    >
      <Focusable shouldFocusOnMount={false}>
        <IconButton
          as="button"
          icon={GlobusSVG}
          iconHeight={20}
          iconWidth={20}
          onClick={toggleDropdown}
          className={styles.languageButton}
        >
          {defaultLocale}
        </IconButton>
        <FadeTransition inProp={isDropdownVisible}>
          <Dropdown toggleDropdown={toggleDropdown}>
            <div className={styles.wrapper}>
              <Locales className={styles.locales} />
            </div>
          </Dropdown>
        </FadeTransition>
      </Focusable>
    </FocusTrap>
  );
};
