'use client';

import FocusTrap from 'focus-trap-react';
import { useState, useEffect } from 'react';
import { useKey } from 'react-use';

import { ChildrenProps } from '@/types/common.types';

import {
  DropdownContextType,
  DropdownProvider,
} from '@/components/Dropdown/contexts/useDropdownContext';

import { DropdownLabel } from '@/components/Dropdown/DropdownLabel';
import { DropdownMenu } from '@/components/Dropdown/DropdownMenu';
import { DropdownOption } from '@/components/Dropdown/DropdownOption';
import { Focusable } from '@/components/Focusable';

// =================================================================

interface DropdownProps
  extends Omit<DropdownContextType, 'isDropdownVisible' | 'toggleDropdown'>,
    ChildrenProps {
  shouldHidden?: boolean;
}

// =================================================================

export const Dropdown = (props: DropdownProps) => {
  const { children, shouldHidden, ...rest } = props;

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleDropdown = () => setIsDropdownVisible(isDropdownVisible => !isDropdownVisible);

  useEffect(() => {
    if (shouldHidden) {
      setIsDropdownVisible(false);
    }
  }, [shouldHidden]);

  useKey('Escape', () => setIsDropdownVisible(false));

  return (
    <DropdownProvider
      isDropdownVisible={isDropdownVisible}
      toggleDropdown={toggleDropdown}
      {...rest}
    >
      <FocusTrap
        active={isDropdownVisible}
        focusTrapOptions={{ allowOutsideClick: true, escapeDeactivates: false }}
      >
        <Focusable shouldFocusOnMount={false}>{children}</Focusable>
      </FocusTrap>
    </DropdownProvider>
  );
};

Dropdown.Label = DropdownLabel;
Dropdown.Menu = DropdownMenu;
Dropdown.Option = DropdownOption;
