import { useTranslations } from 'next-intl';

import { modules, privacyPolicyPath } from '@/app.config';
import AnimeSVG from '@/icons/AnimeSVG';
import CartoonsSVG from '@/icons/CartoonsSVG';
import CollectionsSVG from '@/icons/CollectionsSVG';
import MoviesSVG from '@/icons/MoviesSVG';
import OnlineTvSVG from '@/icons/OnlineTvSVG';
import SeriesSVG from '@/icons/SeriesSVG';
import SubscriptionsSVG from '@/icons/SubscriptionsSVG';
import UzbekKinoSVG from '@/icons/UzbekKinoSVG';

import { NavLink } from '@/components/NavLink';

import styles from '@/layout/MobileMenu/SidebarToggler/SidebarNavPanel.module.scss';

const iconsByModuleId = {
  1: <OnlineTvSVG width={25} height={25} className={styles.sidebarIcon} />,
  3: <MoviesSVG width={25} height={25} className={styles.sidebarIcon} />,
  4: <SeriesSVG width={25} height={25} className={styles.sidebarIcon} />,
  5: <CartoonsSVG width={25} height={25} className={styles.sidebarIcon} />,
  6: <UzbekKinoSVG width={25} height={25} className={styles.sidebarIcon} />,
  7: <AnimeSVG width={25} height={25} className={styles.sidebarIcon} />,
  11: <CollectionsSVG width={25} height={25} className={styles.sidebarIcon} />,
  12: <SubscriptionsSVG width={25} height={25} className={styles.sidebarIcon} />,
};

interface SidebarNavPanelProps {
  hideSidebar: () => void;
}

export const SidebarNavPanel = (props: SidebarNavPanelProps) => {
  const { hideSidebar } = props;

  const t = useTranslations('layout');

  return (
    <nav className={styles.sidebarBody}>
      <ul className={styles.navList}>
        {modules.map(module => {
          return (
            <li key={module.id} className={styles.navListItem}>
              <NavLink
                href={
                  module.moduleType === 'online-tv' || module.moduleType === 'subscriptions'
                    ? `/${module.moduleType}`
                    : `/${module.moduleType}/${module.id}`
                }
                className={styles.navLink}
                activeClassName={styles.active}
                onClick={hideSidebar}
              >
                {iconsByModuleId[module.id]} {t(module.title)}
              </NavLink>
            </li>
          );
        })}

        {privacyPolicyPath && (
          <li className={styles.navListItem}>
            <NavLink
              href={privacyPolicyPath.href}
              className={styles.navLink}
              activeClassName={styles.active}
              onClick={hideSidebar}
            >
              <span className={styles.profileLinkIcon}>{privacyPolicyPath.icon}</span>
              {t(privacyPolicyPath.title)}
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
};
