'use client';

import { useTranslations } from 'next-intl';

import IpAddressSVG from '@/icons/IpAddressSVG';

import { InfoLine } from '@/components/InfoLine';

import styles from '@/layout/MainFooter/MainFooter.module.scss';

import { useNetwork } from '@/contexts/NetworkContext';

export const IpAdress = () => {
  const t = useTranslations('layout');

  const { ipAddress } = useNetwork();

  return (
    <InfoLine className={styles.infoLine} icon={<IpAddressSVG />} as="span">
      {t('footer.ipAddress', { ipAddress })}
    </InfoLine>
  );
};
