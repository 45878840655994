'use client';

import { useKey } from 'react-use';

import { ChildrenProps } from '@/types/common.types';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';

import { Focusable } from '@/components/Focusable';

import styles from '@/layout/MobileMenu/SidebarToggler/Sidebar.module.scss';
import { SidebarTopPanel } from '@/layout/MobileMenu/SidebarToggler/SidebarTopPanel';

import { useStore } from '@/contexts/StoreContext';

export const Sidebar = (props: ChildrenProps) => {
  const { children } = props;

  const { hideSidebar } = useStore(state => state);

  useLockBodyScroll();
  useKey('Escape', hideSidebar);

  return (
    <Focusable className={styles.sidebar}>
      <SidebarTopPanel />
      {children}
    </Focusable>
  );
};
