import type { ClassNameProps, SVGProps } from '@/types/common.types';

// ===============================================================

type SubscriptionsSVGProps = ClassNameProps & SVGProps;

// ===============================================================

const SubscriptionsSVG = (props: SubscriptionsSVGProps) => {
  const { className, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="currentColor"
      viewBox="0 0 426.667 426.667"
      xmlSpace="preserve"
      className={className}
      {...rest}
    >
      <rect x="85.333" y="0" width="256" height="42.667" />
      <path
        d="M384,170.667H42.667C19.093,170.667,0,189.76,0,213.333V384c0,23.573,19.093,42.667,42.667,42.667H384
				c23.573,0,42.667-19.093,42.667-42.667V213.333C426.667,189.76,407.573,170.667,384,170.667z M170.667,368.32V229.013l128,69.653
				L170.667,368.32z"
      />
      <rect x="42.667" y="85.333" width="341.333" height="42.667" />
    </svg>
  );
};

export default SubscriptionsSVG;
