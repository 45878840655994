import { useTranslations } from 'next-intl';

import { modules } from '@/app.config';

import { NavLink } from '@/components/NavLink';

import { ExtraNavDropDown } from '@/layout/DesktopMenu/ExtraNavDropDown';
import styles from '@/layout/DesktopMenu/PrimaryNav.module.scss';

const extraOptions = modules.filter(module => !module.isVisible);

export const PrimaryNav = () => {
  const t = useTranslations('layout');

  return (
    <ul className={styles.primaryNav}>
      {modules.map((module, key) => {
        if (!module.isVisible) return null;

        if (module.moduleType === 'online-tv' || module.moduleType === 'subscriptions') {
          return (
            <li key={key} className={styles.navItem}>
              <NavLink
                href={`/${module.moduleType}`}
                className={styles.navLink}
                activeClassName={styles.active}
              >
                {t(module.title)}
              </NavLink>
            </li>
          );
        }

        return (
          <li key={key} className={styles.navItem}>
            <NavLink
              href={`/${module.moduleType}/${module.id}`}
              className={styles.navLink}
              activeClassName={styles.active}
            >
              {t(module.title)}
            </NavLink>
          </li>
        );
      })}

      <li className={styles.navItem}>
        <ExtraNavDropDown options={extraOptions} />
      </li>
    </ul>
  );
};
