'use client';

import { ForwardedRef, useEffect } from 'react';

import clsx from 'clsx';
import queryString from 'query-string';

import TwistedArrowSVG from '@/icons/TwistedArrowSVG';
import { Link } from '@/navigation';

import { useHover } from '@/hooks/useHover';

import styles from '@/layout/SearchBar/SearchResultsButton.module.scss';

// =================================================================

interface SearchResultsButtonProps {
  query: string;
  toggleSearchBar: () => void;
  onHovered: (index: number) => void;
  isHighlighted: boolean;
}

// =================================================================

export const SearchResultsButton = (props: SearchResultsButtonProps) => {
  const { query, toggleSearchBar, onHovered, isHighlighted } = props;

  const { ref, isHovered } = useHover();

  useEffect(() => {
    if (isHovered) {
      onHovered(1);
    }
  }, [isHovered, onHovered]);

  const href = queryString.stringifyUrl({ url: '/search', query: { query } });

  return (
    <Link
      href={href}
      ref={ref as ForwardedRef<HTMLAnchorElement>}
      className={clsx({
        [styles.searchResultsButton]: true,
        [styles.highlighted]: isHighlighted,
      })}
      onClick={toggleSearchBar}
    >
      Все результаты <TwistedArrowSVG width={16} height={12} />
    </Link>
  );
};
