'use client';

import CrossSVG from '@/icons/CrossSVG';
import NotificationsSVG from '@/icons/NotificationsSVG';

import { IconButton } from '@/components/Buttons';

import { LocalesMobile } from '@/layout/Locales/LocalesMobile';
import styles from '@/layout/MobileMenu/SidebarToggler/SidebarTopPanel.module.scss';

import { useStore } from '@/contexts/StoreContext';

export const SidebarTopPanel = () => {
  const { hideSidebar } = useStore(state => state);

  return (
    <div className={styles.topPanel}>
      <LocalesMobile onClick={hideSidebar} />
      <IconButton
        as="link"
        href="/notifications"
        icon={NotificationsSVG}
        iconWidth={25}
        iconHeight={25}
        onClick={hideSidebar}
        className={styles.notificationsIcon}
      />
      <IconButton
        as="button"
        icon={CrossSVG}
        iconWidth={20}
        iconHeight={20}
        onClick={hideSidebar}
        className={styles.closeButton}
      />
    </div>
  );
};
