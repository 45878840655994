// =================================================================
import { useEffect, JSX } from 'react';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps, SVGProps } from '@/types/common.types';

import { useDropdownContext } from '@/components/Dropdown/contexts/useDropdownContext';
import { useHover } from '@/hooks/useHover';

import { IconButton } from '@/components/Buttons/IconButton';
import styles from '@/components/Dropdown/DropdownLabel.module.scss';

// =================================================================

interface DropdownLabelProps extends Partial<ChildrenProps>, ClassNameProps {
  icon: (props: SVGProps) => JSX.Element;
  onClick?: () => void;
  iconWidth?: number;
  iconHeight?: number;
  title?: string;
}

// =================================================================

export const DropdownLabel = (props: DropdownLabelProps) => {
  const {
    icon: Icon,
    children,
    className,
    onClick,
    iconWidth = 20,
    iconHeight = 20,
    title,
  } = props;
  const { toggleDropdown, trigger } = useDropdownContext();

  const { ref, isHovered } = useHover<HTMLButtonElement>();

  useEffect(() => {
    if (trigger === 'hover' && isHovered) {
      toggleDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, trigger]);

  return (
    <IconButton
      as="button"
      title={title}
      icon={Icon}
      iconHeight={iconHeight}
      iconWidth={iconWidth}
      onClick={trigger === 'click' ? toggleDropdown : onClick}
      className={clsx(className, styles.dropdownLabel)}
      ref={ref}
    >
      {children}
    </IconButton>
  );
};
