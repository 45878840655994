import parseHTML from 'html-react-parser';
import { ForwardedRef, useEffect } from 'react';

import clsx from 'clsx';
import Image from 'next/image';

import IMDbSVG from '@/icons/RatingImdbSVG';
import KinopoiskSVG from '@/icons/RatingKinopoiskSVG';
import { Link } from '@/navigation';

import { useHover } from '@/hooks/useHover';

import { MovieList } from '@/api/types/movie.types';

import styles from '@/layout/SearchBar/SuggestionCard.module.scss';

// =================================================================

interface SuggestionCardProps {
  movie: MovieList;
  onHovered: (index: number) => void;
  isHighlighted: boolean;
  index: number;
}

// =================================================================

export const SuggestionCard = (props: SuggestionCardProps) => {
  const { movie, isHighlighted, onHovered, index } = props;

  const { moduleId, id, poster, titleEn: originalTitle, title, year, rating, genres } = movie;

  const { ref, isHovered } = useHover();

  useEffect(() => {
    if (isHovered) {
      onHovered(index);
    }
  }, [index, isHovered, onHovered]);

  return (
    <Link href={`/watch/${moduleId}/${id}`} prefetch={false} className={styles.suggestionWrapper}>
      <div
        ref={ref as ForwardedRef<HTMLDivElement>}
        className={clsx({
          [styles.suggestion]: true,
          [styles.highlighted]: isHighlighted,
        })}
      >
        <Image
          src={poster}
          alt="Movie poster"
          className={styles.moviePoster}
          width={146}
          height={220}
        />

        <div className={styles.movieDetails}>
          <div className={styles.titlesBlock}>
            <h2 className={styles.mainTitle}>{title}</h2>
            {originalTitle && <h3 className={styles.originalTitle}>{parseHTML(originalTitle)}</h3>}
          </div>
          <div className={styles.metaInfo}>
            {year}
            {genres ? ` / ${genres[0].title}` : ''}
          </div>
        </div>
        <div className={styles.movieRatings}>
          <div className={styles.rating}>
            <IMDbSVG width={20} height={20} className={styles.ratingIcon} />
            {rating?.imdb ? rating?.imdb.rating : '--'}
          </div>
          <div className={styles.rating}>
            <KinopoiskSVG width={20} height={20} className={styles.ratingIcon} />
            {rating?.kp ? rating?.kp.rating : '--'}
          </div>
        </div>
      </div>
    </Link>
  );
};
