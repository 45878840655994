import type { SVGProps } from '@/types/common.types';

const TwistedArrowSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 17 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.9999 0.00119651L9.59992 0V1.20001H14.9999C15.331 1.20001 15.5999 1.47012 15.5999 1.80118V6.60004C15.5999 6.9311 15.3309 7.20001 14.9999 7.20001H2.29689L5.04144 4.45546L4.19301 3.60704L0 7.79999L4.20001 12L5.04844 11.1516L2.29689 8.40002H15C15.9926 8.40002 16.8 7.59258 16.8 6.60004V1.80118C16.8 0.808583 15.9925 0.00119651 14.9999 0.00119651Z" />
    </svg>
  );
};

export default TwistedArrowSVG;
