import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cnnew/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cnnew/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cnnew/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/components/AppsIcons/AppsIcons.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/components/InfoLine/InfoLine.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/components/Logo/Logo.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/NetworkContext/NetworkContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/QueryContext/QueryContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/StoreContext/StoreContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/contexts/VerificationContext/VerificationContext.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/DesktopMenu/DesktopMenu.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SearchButton"] */ "/var/www/cnnew/src/layout/DesktopMenu/SearchButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecondaryNav"] */ "/var/www/cnnew/src/layout/DesktopMenu/SecondaryNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SelectedMenu"] */ "/var/www/cnnew/src/layout/DesktopMenu/SelectedMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IpAdress"] */ "/var/www/cnnew/src/layout/MainFooter/IpAdress.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/MainFooter.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainFooter/MainFooterInfo.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MainHeader/MainHeader.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MobileMenu/MobileMenu.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SelectedMenuMobile"] */ "/var/www/cnnew/src/layout/MobileMenu/SelectedMenuMobile.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/cnnew/src/layout/MobileMenu/SidebarToggler/SidebarToggler.tsx");
