import FacebookSVG from '@/icons/SocialFacebookSVG';
import InstagramSVG from '@/icons/SocialInstagramSVG';
import TelegramSVG from '@/icons/SocialTelegramSVG';

import styles from '@/components/SocialProfilesPanel/SocialProfilesPanel.module.scss';

// =================================================================

export const SocialProfilesPanel = () => {
  return (
    <div className={styles.socialProfilesPanel}>
      <a
        href={process.env.NEXT_PUBLIC_CINERAMA_FACEBOOK_URL}
        title="Facebook"
        className={styles.socialLink}
      >
        <FacebookSVG width={18} height={18} />
      </a>
      <a
        href={process.env.NEXT_PUBLIC_CINERAMA_INSTAGRAM_URL}
        title="Instagram"
        className={styles.socialLink}
      >
        <InstagramSVG width={18} height={18} />
      </a>
      <a
        href={process.env.NEXT_PUBLIC_CINERAMA_TELEGRAM_URL}
        title="Telegram"
        className={styles.socialLink}
      >
        <TelegramSVG width={18} height={18} />
      </a>
    </div>
  );
};
