import type { SVGProps } from '@/types/common.types';

const GlobusSVG = (props: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.65169 7.90698C1.48424 8.57699 1.39535 9.27811 1.39535 10C1.39535 10.7219 1.48424 11.423 1.65169 12.093H5.77072C5.65981 11.4089 5.59613 10.7146 5.58155 10.0145C5.58135 10.0048 5.58135 9.99516 5.58155 9.98547C5.59613 9.28545 5.65981 8.59106 5.77072 7.90698H1.65169ZM2.13182 6.51163H6.06597C6.53323 4.72873 7.32807 3.04222 8.41492 1.54104C5.59805 2.06558 3.26369 3.96244 2.13182 6.51163ZM10 1.76626C8.85758 3.17356 8.01543 4.78871 7.51359 6.51163H12.4864C11.9846 4.78871 11.1424 3.17356 10 1.76626ZM12.8138 7.90698H7.18621C7.06292 8.5944 6.99237 9.29402 6.9769 10C6.99237 10.706 7.06292 11.4056 7.18621 12.093H12.8138C12.9371 11.4056 13.0076 10.706 13.0231 10C13.0076 9.29402 12.9371 8.5944 12.8138 7.90698ZM14.2293 12.093C14.3402 11.4089 14.4039 10.7146 14.4185 10.0145C14.4187 10.0048 14.4187 9.99516 14.4185 9.98547C14.4039 9.28545 14.3402 8.59106 14.2293 7.90698H18.3483C18.5158 8.57699 18.6047 9.27811 18.6047 10C18.6047 10.7219 18.5158 11.423 18.3483 12.093H14.2293ZM12.4864 13.4884H7.51359C8.01543 15.2113 8.85758 16.8264 10 18.2337C11.1424 16.8264 11.9846 15.2113 12.4864 13.4884ZM8.41491 18.459C7.32807 16.9578 6.53323 15.2713 6.06597 13.4884H2.13182C3.26369 16.0376 5.59804 17.9344 8.41491 18.459ZM11.5851 18.459C12.6719 16.9578 13.4668 15.2713 13.934 13.4884H17.8682C16.7363 16.0376 14.402 17.9344 11.5851 18.459ZM17.8682 6.51163H13.934C13.4668 4.72873 12.6719 3.04222 11.5851 1.54104C14.402 2.06558 16.7363 3.96244 17.8682 6.51163ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="white"
      />
    </svg>
  );
};

export default GlobusSVG;
