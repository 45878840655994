import type { ClassNameProps } from '@/types/common.types';

const CineramaLogoSVG = ({ className }: ClassNameProps) => {
  return (
    <svg
      viewBox="0 0 165 26"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={className}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="INDEX" transform="translate(-425.000000, -22.000000)">
          <g id="Group" transform="translate(425.000000, 22.000000)">
            <path
              d="M7.81597009e-14,0 L20.6750155,0 L20.6750155,25.8209746 L7.81597009e-14,25.8209746 L7.81597009e-14,0 Z M13.2729288,1.83245626 C6.95271441,1.83245626 1.8296371,6.79202254 1.8296371,12.9105174 C1.8296371,19.0290123 6.95271441,23.9885183 13.2729288,23.9885183 C15.2758097,23.9885183 17.1578695,23.490002 18.7953629,22.6148127 L18.7953629,18.8780781 C17.3303911,20.1492738 15.3950755,20.9228565 13.2729288,20.9228565 C8.70182117,20.9228565 4.99643239,17.335731 4.99643239,12.9105174 C4.99643239,8.48530381 8.70182117,4.89817832 13.2729288,4.89817832 C15.3950755,4.89817832 17.3303911,5.67176104 18.7953629,6.9429567 L18.7953629,3.20616186 C17.1578695,2.33103285 15.2758097,1.83245626 13.2729288,1.83245626 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M22.5127947,0 L28.9450217,0 L28.9450217,25.8209746 L22.5127947,25.8209746 L22.5127947,0 Z M24.2842742,1.83245626 L24.2842742,23.9885183 L27.040245,23.9885183 L27.040245,1.83245626 L24.2842742,1.83245626 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M30.7828009,0 L50.9983716,0 L50.9983716,25.8209746 L30.7828009,25.8209746 L30.7828009,0 Z M32.6008065,23.9885183 L35.3452621,23.9885183 L35.3452621,9.44860259 L49.0675403,23.9885183 L49.0675403,1.83245626 L46.3230847,1.83245626 L46.3230847,16.6031643 L32.6008065,1.83245626 L32.6008065,23.9885183 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M52.8361507,0 L65.7006048,0 L65.7006048,25.8209746 L52.8361507,25.8209746 L52.8361507,0 Z M63.8709677,21.2190106 L57.4672379,21.2190106 L57.4672379,14.2952412 L63.8709677,14.2952412 L63.8709677,11.5257334 L57.4672379,11.5257334 L57.4672379,4.60196402 L63.8709677,4.60196402 L63.8709677,1.83245626 L57.5338153,1.83245626 L54.7227823,1.83245626 L54.7227823,5.1581094 L54.7227823,11.1855874 L54.7227823,14.5111735 L54.7227823,21.3552421 L54.7227823,23.9885183 L57.5338153,23.9885183 L63.8709677,23.9885183 L63.8709677,21.2190106 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            ></path>
            <path
              d="M67.5302419,0 L85.9207623,0 L85.9207623,25.8209746 L67.5302419,25.8209746 L67.5302419,0 Z M76.3298299,1.83245626 C73.986125,1.83245626 71.6421068,1.83245626 69.2984646,1.83245626 L69.2984646,14.3186143 L69.2984646,23.9885183 L72.0544355,23.9885183 L72.0544355,14.2952412 L73.4382967,14.2952412 L80.322348,23.9885183 L83.9969758,23.9885183 L77.0337187,14.2701284 C79.5949162,13.9201894 81.880878,11.6714125 81.880878,8.05132465 C81.880878,4.10204972 79.3273921,1.83245626 76.3298299,1.83245626 Z"
              id="Combined-Shape"
              fill="#FFFF4D"
            ></path>
            <path
              d="M113.506543,0 L139.253271,0 L139.253271,25.8209746 L113.506543,25.8209746 L113.506543,0 Z M115.433468,23.9885183 L118.605401,23.9885183 L120.296983,11.1567977 L126.494456,23.9885183 L132.691869,11.1567977 L134.383511,23.9885183 L137.555444,23.9885183 L133.960928,1.83245626 L126.494456,17.1892216 L119.027983,1.83245626 L115.433468,23.9885183 Z"
              id="Combined-Shape"
              fill="#FFFF4D"
            ></path>
            <path
              d="M141.092323,0 L165,0 L165,25.8209746 L141.092323,25.8209746 L141.092323,0 Z M153.048008,1.93059535 L142.925652,23.9885183 L146.31605,23.9885183 L148.725004,18.192017 C151.606904,18.192017 154.489111,18.192017 157.371011,18.192017 L159.780026,23.9885183 L163.170363,23.9885183 L153.048008,1.93059535 Z"
              id="Combined-Shape"
              fill="#FFFF4D"
            ></path>
            <path
              d="M87.7598144,0 L111.667491,0 L111.667491,25.8209746 L87.7598144,25.8209746 L87.7598144,0 Z M99.6558705,1.93059535 L89.5335152,23.9885183 L92.9239131,23.9885183 L95.3328671,18.192017 C98.2147669,18.192017 101.096974,18.192017 103.978874,18.192017 L106.387889,23.9885183 L109.778226,23.9885183 L99.6558705,1.93059535 Z"
              id="Combined-Shape"
              fill="#FFFF4D"
            ></path>
            <path
              d="M72.0544355,4.60196402 L74.563048,4.60196402 C77.2273178,4.60196402 78.6986067,6.0041121 78.6986067,8.09333795 C78.6986067,10.1825638 77.3908185,11.2949411 74.5875515,11.2949411 L72.0544355,11.2949411 L72.0544355,4.60196402 Z"
              id="path78-path"
              fill="#FFFF4D"
            ></path>
            <path
              d="M102.991587,15.8158984 L99.6558705,7.78963264 L96.320216,15.8158984 C98.5440882,15.8158984 100.767653,15.8158984 102.991587,15.8158984 Z"
              id="path118-path"
              fill="#FFFF67"
            ></path>
            <path
              d="M156.383724,15.8158984 L153.048008,7.78963264 L149.712353,15.8158984 C151.936225,15.8158984 154.15979,15.8158984 156.383724,15.8158984 Z"
              id="path118-path"
              fill="#FFFF67"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CineramaLogoSVG;
