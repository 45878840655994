'use client';

import FocusTrap from 'focus-trap-react';
import { useState } from 'react';
import { useKey } from 'react-use';

import { FadeTransition } from '@/components/Animations';
import { UserAvatar } from '@/components/Avatars/UserAvatar';
import { Focusable } from '@/components/Focusable';

import { MainCredentials } from '@/layout/MainCredentials';
import { Dropdown } from '@/layout/UserDropdownMenu/Dropdown';
import styles from '@/layout/UserDropdownMenu/UserDropdownMenu.module.scss';
import { UserProfileLinks } from '@/layout/UserDropdownMenu/UserProfileLinks';

import { useNetwork } from '@/contexts/NetworkContext';

// =================================================================

export const UserDropdownMenu = () => {
  const { isTasix } = useNetwork();

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const toggleDropdown = () => setIsDropdownVisible(isDropdownVisible => !isDropdownVisible);

  useKey('Escape', () => setIsDropdownVisible(false));

  return (
    <FocusTrap
      active={isDropdownVisible}
      focusTrapOptions={{ allowOutsideClick: true, escapeDeactivates: false }}
    >
      <Focusable shouldFocusOnMount={false}>
        <UserAvatar
          as="button"
          onClick={e => {
            e.stopPropagation();
            toggleDropdown();
          }}
        />
        <FadeTransition inProp={isDropdownVisible}>
          <Dropdown toggleDropdown={toggleDropdown}>
            <div className={styles.topPanel}>
              <MainCredentials />
            </div>
            <UserProfileLinks isTasix={isTasix} toggleDropdown={toggleDropdown} />
          </Dropdown>
        </FadeTransition>
      </Focusable>
    </FocusTrap>
  );
};
