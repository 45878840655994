import type { SVGProps } from '@/types/common.types';

const CollectionsSVG = (props: SVGProps) => {
  return (
    <svg
      viewBox="0 0 18 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9 0C8.40034 0 7.7971 0.106805 7.35533 0.316008L0.593909 3.52135C0.373011 3.62597 0.211301 3.74312 0.117487 3.85241C0.0235893 3.96169 1.04565e-05 4.05139 1.04565e-05 4.13078C1.04565e-05 4.21014 0.0235893 4.29989 0.117487 4.40916C0.211322 4.51845 0.373032 4.64315 0.593909 4.74773L7.35533 7.94561C8.23892 8.36411 9.75456 8.36411 10.6382 7.94561L17.4061 4.74797C17.627 4.64338 17.7821 4.51867 17.876 4.4094C17.9699 4.30012 18 4.21037 18 4.13103C18 4.05164 17.9699 3.96192 17.876 3.85265C17.7821 3.74337 17.627 3.62621 17.4061 3.52159L10.6382 0.316249C10.1963 0.107021 9.5996 0.000120318 9 0.000120318V0ZM1.83404 5.80853L0.593909 6.38806C0.373053 6.49265 0.21788 6.60968 0.124003 6.71911C0.0301262 6.82852 0 6.91778 0 6.99749C0 7.07721 0.0301262 7.1665 0.124003 7.27587C0.21788 7.3853 0.373053 7.50986 0.593909 7.61443L1.88615 8.2314C1.89279 8.2335 1.89933 8.23601 1.90573 8.23893L7.35517 10.8197C8.2388 11.2382 9.75441 11.2382 10.638 10.8197L16.0942 8.23893L17.4059 7.61437C17.6268 7.50978 17.782 7.38527 17.8758 7.27581C17.9697 7.16638 17.9998 7.07714 17.9998 6.99743C17.9998 6.91771 17.9697 6.82842 17.8758 6.71905C17.782 6.60962 17.6268 6.49254 17.4059 6.388L16.1658 5.80847L10.7815 8.3517C9.76675 8.83232 8.22627 8.83232 7.21149 8.3517L1.83368 5.80871L1.83404 5.80853ZM1.83404 8.67511L0.593909 9.25464C0.373053 9.35923 0.21788 9.47627 0.124003 9.5857C0.0301262 9.69513 0 9.78437 0 9.86407C0 9.94382 0.0301262 10.033 0.124003 10.1424C0.21788 10.2519 0.373053 10.3764 0.593909 10.481L7.35533 13.6861C8.23896 14.1046 9.75456 14.1046 10.6382 13.6861L17.4061 10.4813C17.6269 10.3766 17.7821 10.2521 17.876 10.1427C17.9699 10.0333 18 9.94405 18 9.86431C18 9.7846 17.9699 9.69536 17.876 9.58594C17.7821 9.47651 17.6269 9.35947 17.4061 9.25488L16.1661 8.67555L10.7818 11.2185C9.76701 11.6992 8.22654 11.6992 7.21175 11.2185L1.83394 8.67555L1.83404 8.67511Z" />
    </svg>
  );
};

export default CollectionsSVG;
