'use client';

import CrossSVG from '@/icons/CrossSVG';
import SearchSVG from '@/icons/SearchSVG';

import { IconButton } from '@/components/Buttons';

import styles from '@/layout/DesktopMenu/SecondaryNav.module.scss';

import { useStore } from '@/contexts/StoreContext';

export const SearchButton = () => {
  const { isSearchBarVisible, toggleSearchBar } = useStore(state => state);

  return (
    <div className={styles.searchTogglerContainer}>
      <IconButton
        as="button"
        icon={isSearchBarVisible ? CrossSVG : SearchSVG}
        iconWidth={20}
        iconHeight={20}
        onClick={toggleSearchBar}
      />
    </div>
  );
};
