'use client';

import cn from 'classnames';
import { useTranslations } from 'next-intl';

import LogoutSVG from '@/icons/LogoutSVG';
import { ClassNameProps } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import styles from '@/components/Buttons/ExitProfileButton.module.scss';
import { IconButton } from '@/components/Buttons/IconButton';

// =================================================================

type ExitProfileButtonProps = ClassNameProps & {
  onExited?: () => void;
  onExiting?: () => void;
};

// =================================================================

export const ExitProfileButton = (props: ExitProfileButtonProps) => {
  const { className } = props;

  const { signOut } = useVerification();

  const t = useTranslations('layout');

  const handleSignOut = () => {
    signOut();
  };

  return (
    <IconButton
      as="button"
      icon={LogoutSVG}
      iconWidth={16}
      iconHeight={16}
      onClick={handleSignOut}
      className={cn(styles.logoutButtonBase, className)}
    >
      {t('header.logout')}
    </IconButton>
  );
};

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  ExitProfileButton.displayName = 'ExitProfileButton';
}
