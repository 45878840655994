'use client';

import { memo } from 'react';

import UserSVG from '@/icons/UserProfielSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { getInitialsFromName } from '@/helpers/transformations.helpers';

import { AvatarBase, type AvatarBaseProps } from '@/components/Avatars/AvatarBase';

// =================================================================

export const UserAvatar = memo((props: Omit<AvatarBaseProps, 'variant' | 'initials'>) => {
  const { userCredentials } = useVerification();
  const { onClick, className } = props;

  const username = userCredentials?.fullName || userCredentials?.username;
  const initials = getInitialsFromName(username!);

  if (props.as === 'link') {
    return (
      <AvatarBase
        as="link"
        href={props.href!}
        variant={initials ? 'signature' : 'dimmed'}
        initials={initials ? initials : <UserSVG width={18} height={18} />}
        onClick={onClick}
        className={className}
      />
    );
  }

  return (
    <AvatarBase
      as="button"
      type={props.type}
      variant={initials ? 'signature' : 'dimmed'}
      initials={initials ? initials : <UserSVG width={18} height={18} />}
      onClick={onClick}
      className={className}
    />
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  UserAvatar.displayName = 'UserAvatar';
}
